/* Basic styling for the navbar */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #00000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 100;
}

/* Styling for the logo */
.navbar-logo a {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-logo a:hover {
  color: #00b7ff;
}

/* Navigation links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  font-size: 1.1rem;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.nav-links li a:hover {
  color: #00b7ff;
  border-bottom: 2px solid #3498db;
}

/* Menu icon (hamburger) */
.nav-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.nav-menu-icon span {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px;
  transition: all 0.3s ease;
}

.nav-menu-icon.open span:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.nav-menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.nav-menu-icon.open span:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    top: 60px;
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }

  .nav-links.active {
    transform: translateX(0);
  }

  .nav-menu-icon {
    display: flex;
  }
}

/* Add some space when scrolling down */
body {
  padding-top: 60px;
}
