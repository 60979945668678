/* Container for the entire about section */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
  text-align: center;
  overflow: auto;
}

/* For larger screens */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.about-image {
  flex: 1;
  padding-right: 20px;
  text-align: left;
}

.about-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: cover;
}

.about-text {
  flex: 2;
  text-align: left;
  margin-top: 50px;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 20px;
}

.about-container h1,
.about-container h2 {
  color: #ffffff;
}

/* Community Involvement Section */
.community-involvement {
  margin-top: 100px;
  text-align: center;
  padding-top: 50px;
}

.community-involvement h2 {
  color: white;
  margin-bottom: 20px;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

/* Individual slide */
.slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide img {
  width: 100%;
  max-width: 800px;
  height: 500px;
  object-fit: cover;
}

/* Caption styling */
.caption {
  margin-top: 10px;
  color: white;
  font-size: 1rem;
  text-align: center;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-image {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .about-text {
    text-align: center;
    margin-top: 0;
  }

  .about-text p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .slide img {
    height: auto;
    max-width: 100%;
  }

  .about-container {
    padding: 50px 20px;
  }

  .community-involvement {
    padding-top: 50px;
  }

  .caption {
    font-size: 0.9rem;
  }
}
