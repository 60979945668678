/* Loading screen container */
.loading-screen {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background-color: black;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 1;
	visibility: visible;
	transition: opacity 1s ease-out, visibility 1s ease-out;
  }
  
  .loading-screen.fade-out {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
  }
  
  .initials-container {
	position: relative;
  }
  
  .path {
	fill: none;
	stroke-dasharray: 0;
	stroke-dashoffset: 0;
	stroke-width: 8px;
	stroke: white;
	animation: wave 3s infinite ease-in-out;
  }
  
  /* Keyframes for the wave animation */
  @keyframes wave {
	0%, 100% {
	  transform: translateY(0);
	}
	50% {
	  transform: translateY(-10px);
	}
  }
  