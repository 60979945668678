/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base styling for the body */
body {
  font-family: 'Poppins', sans-serif;
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0,0,0,1) 50%, rgba(78,78,78,1) 100%);
  line-height: 1.6;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

body.loading {
  overflow: hidden; /* Prevent scrolling during loading */
}


/* Ensures 100% height for the body and HTML */
html, body {
  height: 100%;
}

/* Global container class for general layout */
.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
}

/* Heading styles */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

/* Paragraph and text styles */
p {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

/* Link styling */
a {
  text-decoration: none;
  color: #3498db;
  transition: color 0.3s ease;
}

a:hover {
  color: #1d6fa5;
}

/* General input and form styling */
input, textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #3498db;
}
